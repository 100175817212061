<template>
  <div class="pbf">
     
     <n-dialog-provider>
       <n-message-provider>
         <TabProducts
           :data="data"
           />
       </n-message-provider>
     </n-dialog-provider>

  </div>
</template>

<script setup>

import { NDialogProvider, NMessageProvider } from 'naive-ui'
import TabProducts from '@/pages/brands-manager/components/TabProducts'

const props = defineProps({
  data: {
    type: Object    
  }
});

useHead(useNuxtApp().$head.getBrandManager());

</script>

<style scoped>
 
</style>

<style>
 
/* products_table */

.products_table {}

.products_table .itm {
  display: flex;
  align-items: center;
}

.products_table .itm .img {
  margin-right: 7px;
}

.products_table .itm .img img {
  min-width: 40px;
  max-width: 40px;
}

.products_table .itm .nm {}

.products_table .ac{
  width: 100px;
  vertical-align: middle;
}

.pointer{
  cursor: pointer;
}

.white-color{
  color: white;
}

.red-color{
  color: red;
}

.green-color{
  color: green;
}


</style>